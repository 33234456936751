document.addEventListener("click", handleBodyClick);

function handleBodyClick(e) {
    const videoTrigger = e.target.closest(".js-videoLightbox");

    if (videoTrigger) {
        e.preventDefault();
        openVideoModal(videoTrigger);
    }
}

function openVideoModal(video) {
    const videoElement = findParent(video, ".c-video__link");
    const videoID = videoElement.getAttribute("data-vid");
    const videoTitle = videoElement.getAttribute("data-vid-title").trim();

    if (!document.getElementById("video-modal")) {
        createVideoModal(videoID, videoTitle);
    }

    document.getElementById("video-modal").classList.add("active");

    // Disable scrolling when the modal is open
    document.body.style.overflow = "hidden";

    // Add event listeners for closing the modal
    document.querySelector(".video-modal-bg").addEventListener("click", closeVideoModal);
    document.querySelector(".video-modal-close").addEventListener("click", closeVideoModal);
}

function createVideoModal(videoID, title) {
    const modalHTML = `
        <div class="video-modal-wrapper video-modal" id="video-modal" aria-hidden="true">
            <div class="video-modal-bg"></div>
            <div class="video-modal-content">
                <button class="video-modal-close" aria-label="Close">x</button>
                <iframe src="https://fast.wistia.net/embed/iframe/${videoID}?autoPlay=true&amp;silentAutoPlay=allow"
                        allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed"
                        name="wistia_embed" allow="autoplay" allowfullscreen
                        mozallowfullscreen webkitallowfullscreen oallowfullscreen
                        msallowfullscreen width="640" height="360" title="${title}">
                </iframe>
            </div>
        </div>`;

    document.body.insertAdjacentHTML("afterbegin", modalHTML);
}

function closeVideoModal() {
    const modal = document.getElementById("video-modal");
    if (modal) {
        modal.classList.remove("active");
        document.body.style.overflow = ""; // Re-enable scrolling
        modal.remove(); // Clean up the modal after closing
    }
}

function findParent(el, selector) {
    while (el && el !== document) {
        if (el.matches(selector)) return el;
        el = el.parentNode;
    }
    return null;
}
